import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm, userForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpSchema } from "../../Yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pic, setPic] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(signUpSchema),
    mode: "all",
    criteriaMode: "all",
  });

  const toast = useToast();

  const toggleShow = () => setShow(!show);

  const postDetails = (pic) => {
    setLoading(true);

    if (pic === undefined) {
      toast({
        title: "Error.",
        description: "Unable to upload",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (pic.type === "image/jpeg" || pic.type === "image/png") {
      const data = new FormData();
      data.append("file", pic);
      data.append("upload_preset", "Social Chit Chat");
      data.append("cloud_name", "jahanzaib887");
      fetch("https://api.cloudinary.com/v1_1/jahanzaib887/image/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setPic(data.url.toString());
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast({
        title: "Please Select an image",
        description: "no image selected",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/user`,
          {
            name: data.name,
            email: data.email,
            password: data.password,
            photo: pic,
          },
          config
        )
        .then((res) => {
          setLoading(false);
          // console.log("Response", res);
          sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          toast({
            title: "Registration Successful",

            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/chats");
        })
        .catch((err) => {
          console.log(err, ">>>>>>>>>");
          setLoading(false);
          toast({
            title: "Error Occurred",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({
        title: "Error Occurred",
        // description: ,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="5px">
        <FormControl id="first-name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Enter Your Name"
            {...register("name")}
            onChange={(e) => setName(e.target.value)}
          />
          <Text color="red">{errors.name?.message}</Text>
        </FormControl>
        <FormControl id="email" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Enter Your Email"
            {...register("email")}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Text color="red">{errors.email?.message}</Text>
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              type={show ? "text" : "password"}
              placeholder="Enter Your Password"
              {...register("password")}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={toggleShow}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text color="red">{errors.password?.message}</Text>
        </FormControl>
        <FormControl id="confirmPassword" isRequired>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <Input
              type={show ? "text" : "password"}
              placeholder="Confirm your password"
              {...register("confirmPassword")}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm">
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text color="red">{errors.confirmPassword?.message}</Text>
        </FormControl>

        <FormControl id="pic" isRequired>
          <FormLabel>Profile Picture</FormLabel>
          <Input
            type="file"
            accept="image/*"
            p={1.5}
            placeholder="Please upload your image"
            onChange={(e) => postDetails(e.target.files[0])}
          />
        </FormControl>

        <Button
          colorScheme="blue"
          width="100%"
          mt={15}
          type="submit"
          isLoading={loading}
          loadingText="Uploading"
          disabled
        >
          Sign Up
        </Button>
      </VStack>
    </form>
  );
};

export default Signup;
