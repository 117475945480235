import { ViewIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ChatState } from "../../Context/ChatProvider";
import UserBadgeItem from "../UserAvatar/UserBadgeItem";
import axios from "axios";
import UserListItem from "../UserAvatar/UserListItem";

const UpdateGroupChatModal = ({ setFetchAgain, fetchAgain, fetchMessages }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, selectedChat, setSelectedChat } = ChatState();
  const [groupChatName, setGroupChatName] = useState();
  const [loading, setLoading] = useState();
  const [search, setSearch] = useState();
  const [searchResult, setSearchResult] = useState();
  const [renameLoading, setRenameLoading] = useState(false);

  const toast = useToast();

  const handleRemove = async (user1) => {
    if (selectedChat.groupAdmin._id !== user._id && user1._id !== user._id) {
      toast({
        title: "Only admin can remove someone",
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "bottom",
      });

      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      };
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/chat/removeFromGroup`,
          {
            chatId: selectedChat._id,
            userId: user1._id,
          },
          config
        )
        .then((res) => {
          user1._id === user._id
            ? setSelectedChat()
            : setSelectedChat(res.data);
          setFetchAgain(!fetchAgain);
          setLoading(false);
          fetchMessages();
        });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error occurred",
        duration: 5000,
        isClosable: true,
        status: "error",
      });
    }
  };
  const handleRename = async () => {
    if (!groupChatName) return;
    try {
      setRenameLoading(true);
      const config = {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      };
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/chat/rename`,
          {
            chatId: selectedChat._id,
            chatName: groupChatName,
          },
          config
        )
        .then((res) => {
          setSelectedChat(res.data);
          setFetchAgain(!fetchAgain);
          setRenameLoading(false);
        })
        .catch((err) => {
          setRenameLoading(false);
          toast({
            title: "Error Occurred",
            description: `${err}`,
            status: "error",
            duration: 50000,
            isClosable: true,
            position: "bottom-left",
          });
        });
    } catch (error) {
      setRenameLoading(false);
      toast({
        title: "Error Occurred",
        description: `${error}`,
        status: "error",
        duration: 50000,
        isClosable: true,
        position: "bottom-left",
      });
    }
    setGroupChatName("");
  };
  const handleSearch = async (query) => {
    setSearch(query);
    if (!query) {
      return;
    }

    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user?search=${search}`,
          config
        )
        .then((res) => {
          setLoading(false);
          setSearchResult(res.data.data);
        });
    } catch (error) {
      toast({
        title: "Error Occurred!",
        description: "Failed to Load the Search Results",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  const handleAddUser = async (user1) => {
    if (selectedChat.users.find((u) => u._id === user1._id)) {
      toast({
        title: "User Already in Group",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (selectedChat.groupAdmin._id !== user._id) {
      toast({
        title: "Only Admin can add users to group",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      };
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/chat/addToGroup`,
          {
            chatId: selectedChat._id,
            userId: user1._id,
          },
          config
        )
        .then((res) => {
          setSelectedChat(res.data);
          setFetchAgain(!fetchAgain);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error occurred",
        duration: 5000,
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <IconButton
        display={{ base: "flex" }}
        icon={<ViewIcon />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedChat.chatName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedChat?.users.map((user) => {
              return (
                <UserBadgeItem
                  key={user._id}
                  user={user}
                  handleFunction={() => handleRemove(user)}
                />
              );
            })}
            <FormControl display={"flex"}>
              <Input
                placeholder="Group Name"
                mb={3}
                value={groupChatName}
                onChange={(e) => setGroupChatName(e.target.value)}
              />
              <Button
                isLoading={renameLoading}
                ml={1}
                colorScheme="teal"
                variant={"solid"}
                onClick={handleRename}
              >
                Update
              </Button>
            </FormControl>
            <FormControl>
              <Input
                mb={1}
                placeholder="Add User to Group"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </FormControl>
            {loading ? (
              // <ChatLoading />
              <Spinner />
            ) : (
              searchResult
                ?.slice(0, 4)
                .map((user) => (
                  <UserListItem
                    key={user._id}
                    user={user}
                    handleFunction={() => handleAddUser(user)}
                  />
                ))
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              variant={"solid"}
              mr={3}
              onClick={() => handleRemove()}
            >
              Leave Group
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateGroupChatModal;
