import * as yup from "yup";

const signUpSchema = yup.object({
  name: yup.string().required("Required Field"),
  email: yup.string().email("Incorrect Email").required("Required Email"),
  password: yup.string().required("Required Field").min(4).max(8),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Password Doesn't Match")
    .required("Required Field"),
});

const loginSchema = yup.object({
  email: yup.string().email("Incorrect Email").required("Required Field"),
  password: yup.string().min(4).max(8).required("Required Field"),
});

export { signUpSchema, loginSchema };
