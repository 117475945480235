import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, handleSubmit } from "react-hook-form";
import { loginSchema } from "../../Yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(loginSchema),
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(!show);
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    const config = {
      Headers: {
        "Content-type": "application/json",
      },
    };
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/login`,
        {
          email: data.email,
          password: data.password,
        },
        config
      )

      .then((res) => {
        setLoading(false);
        console.log("Response", res.data);
        sessionStorage.setItem("userInfo", JSON.stringify(res.data));
        navigate("/chats");
        toast({
          title: "Logged In successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Error Occurred",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="5px">
        <FormControl id="email" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            placeholder="Enter Your Email"
            {...register("email")}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Text color="red">{errors.email?.message}</Text>
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              value={password}
              type={show ? "text" : "password"}
              placeholder="Enter Your Password"
              {...register("password")}
              onChange={(e) => setPassword(e.target.value)}
            />

            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={toggleShow}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text color={"red"}>{errors.password?.message}</Text>
        </FormControl>

        <Button
          colorScheme="blue"
          width="100%"
          mt={15}
          type="submit"
          isLoading={loading}
          loadingText="Signing In"
        >
          Log In
        </Button>
        <Button
          variant={"solid"}
          colorScheme="red"
          width={"100%"}
          onClick={() => {
            setEmail("guest@example.com");
            setPassword("123456");
          }}
        >
          Get Guest User Credentials
        </Button>
      </VStack>
    </form>
  );
};

export default Login;
