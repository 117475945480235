// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./41809.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    min-height: 100vh;
        display: flex;
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
        background-position: center;
        background-size: cover;
}

::-webkit-scrollbar {
    width: 0px;
}


::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.281);
}


::-webkit-scrollbar-thumb:hover {
    background: #555;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;IACI,iBAAiB;QACb,aAAa;QACb,yDAAoC;QACpC,2BAA2B;QAC3B,sBAAsB;AAC9B;;AAEA;IACI,UAAU;AACd;;;AAGA;IACI,sCAAsC;AAC1C;;;AAGA;IACI,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');\n.App {\n    min-height: 100vh;\n        display: flex;\n        background-image: url(\"./41809.jpg\");\n        background-position: center;\n        background-size: cover;\n}\n\n::-webkit-scrollbar {\n    width: 0px;\n}\n\n\n::-webkit-scrollbar-thumb {\n    background: rgba(136, 136, 136, 0.281);\n}\n\n\n::-webkit-scrollbar-thumb:hover {\n    background: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
